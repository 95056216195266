const styles = {
  menu() {
    return {
      enterCls: 'slideInTop',
      leaveCls: 'slideOutTop',
    };
  },
};

export default styles;
